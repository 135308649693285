import React from 'react'
import { CardRouter, CardContainer, ImageWrapper, Image, TextWrapper, Title } from './CardElements'

const Card = (props) => {
    return (
        <>
            <CardRouter to={props.to}>
                <CardContainer>
                    <ImageWrapper>
                        <Image src={props.imgsrc} atl=''></Image>
                    </ImageWrapper>

                    <TextWrapper>
                        <Title>{props.title}</Title>
                    </TextWrapper>
                </CardContainer> 
            </CardRouter>
        </>
    )
}

export default Card
