import React from 'react'

import { PopupContainer, PopupWrapper, CloseButton } from './PopupElements'

const Popup = (props) => {
    return (props.trigger) ? (
        <>
            <PopupContainer>
                <PopupWrapper>
                    <CloseButton onClick={() => props.setTrigger(false)}>close</CloseButton>
                    {props.children}
                </PopupWrapper>
            </PopupContainer>
        </>
    ) : "";
}

export default Popup
