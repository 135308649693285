import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

export const ServicesContainer = styled.div `
    max-width: 1200px;
    margin: auto;
    border-bottom: 1px solid #9d998f;
`

export const ServicesWrapper = styled.div `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`

export const Title = styled.div `
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 20px;

    @media screen and (max-width: 480px) {
        font-size: 25px;
    }
`

export const Subtitle = styled.div `
    font-family: 'Cormorant Garamond', serif;
    color: #353535;
    font-size: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const ServicesGrid = styled.div `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

export const ServicesLink = styled(LinkRouter) `
    border: 1px solid #9d998f;
    background: #f9f9f4;
    text-decoration: none;
    margin: 10px;
    font-size: 20px;
    color: #353535;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    text-transform: uppercase;
    padding: 20px;
    

    &:hover {
        transform: scale(1.1);
    }
`