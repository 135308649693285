import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const ContactContainer = styled.div `
    padding-top: 20px;
    background: #f9f9f4;
`

export const ContactWrapper = styled.div `
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
`

export const ImageWrapper = styled.div `
    width: 100%;
    height: 300px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        height: 250px;
    }
`

export const Image = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
`

export const Title = styled.div `
    text-align: center;
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`   

export const PageDesc = styled.div `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #9d998f;
`

export const Paragraph = styled.p `
    font-family: 'Cormorant Garamond', serif;
    color: #353535;
    font-size: 20px;
    margin-bottom: 10px;
    max-width: 800px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const ButtonWrapper = styled.div `
    display: flex;
    //align-items: center;
    padding-bottom: 20px;
`
export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const Button = styled(LinkRouter) `
    background: #8cb7bd;
    text-decoration: none;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Cormorant Garamond', serif;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #a0c9ce;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`