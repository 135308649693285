import React from 'react'
import ContactInfoSection from '../../components/ContactInfoSection'
import { ContactContainer, ContactWrapper, ImageWrapper, Image, Title, 
    PageDesc, Paragraph } from './ContactElements'
import img from '../../images/IMG_0323e.jpeg'
import ContactForm from '../../components/ContactForm'

const Contact = () => {
    return (
        <>
            <ContactContainer>
                <ContactWrapper>
                    <ImageWrapper>
                        <Image src={img}></Image>
                    </ImageWrapper>

                    <Title>- Contact Us -</Title>
                    <PageDesc>
                        <Paragraph>
                            Andrea's Permanent Makeup is open and available for service 7 days a week 
                            by appointments only. For your safety and ours, we continue to implement 
                            our sanitation rituals, social distancing, disinfecting all areas and 
                            wearing masks and gloves. 
                        </Paragraph>
                    </PageDesc>

                    <ContactInfoSection/>
                    <ContactForm/>
                </ContactWrapper>
            </ContactContainer>
        </>
    )
}

export default Contact
