import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa';
import {Link as LinkRouter} from 'react-router-dom';

export const SidebarContainer = styled.div `
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    align-items: center;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    left: ${({isOpen}) => (isOpen ? '0' : '100%')};
`

export const CloseIcon = styled(FaTimes) `
    color: #403825;
`

export const SidebarIcon = styled.div `
    position: absolute;
    top: 1.4rem;
    right: 1.6rem;
    background: transparent;
    font-size: 1.6rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div `
    color: #fff;
`

export const SidebarMenu = styled.ul `
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

export const SidebarLink = styled(LinkRouter) `
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    list-style: none;
    color: #403825;
    cursor: pointer;

    &:hover {
        color: #d3ba7b;
        transition: 0.2s ease-in-out;
    }
`

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

export const SidebarRoute = styled(LinkRouter)`
    border-radius: 3px;
    background: #fff;
    white-space: nowrap;
    padding: 14px 30px;
    color: #403825;
    font-size: 14px;
    outline: none;
    border: solid;
    border-radius: 50px;
    border-width: 2.3px;
    border-color: #403825;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #d3ba7b;
        color: #fff;
        border-color: #fff;
    }
`

