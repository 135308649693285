import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const IntroContainer = styled.div `
    background: #f9f9f4;
    max-width: 1200px;
    margin: auto;
    border-bottom: 1px solid #9d998f;
`

export const IntroWrapper = styled.div `
    position: auto;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const IntroContent = styled.div `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 650px;
`

export const Title = styled.h1 `
    color: #403825;
    color: black;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin: 15px 0;

    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`

export const Subtitle = styled.p `
    color: #403825;
    color: black;
    
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 2.4px;
    margin-bottom: 15px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const Paragraph = styled.p `
    font-family: 'Cormorant Garamond', serif;
    color: #353535;
    font-size: 20px;
    margin-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const Other = styled.p `
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    color: #353535;
    font-size: 20px;
    margin-bottom: 15px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const Other2 = styled.p `
    color: #353535;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`

export const ButtonColumn = styled.div `
    margin: auto;
`

export const ButtonWrapper = styled.div `
    display: flex;
`
export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const Button = styled(LinkRouter) `
    background: #e289a5;
    text-decoration: none;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Cormorant Garamond', serif;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #f4c0d1;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
`