import styled from 'styled-components'

export const OwnerContainer = styled.div `
    background: #f9f9f4;
`

export const OwnerWrapper = styled.div ` 
    padding: 20px 0;
    display: grid;
    z-index: 1;
    width: 80%;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    border-bottom: 1px solid #9d998f;
`

export const OwnerContent = styled.div ` 
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';
    max-width: 1000px;
    
    @media screen and (max-width: 768px) {
        grid-template-areas: 'col1 col1' 'col2 col2';
    }
`

export const TextColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    margin: auto;
    grid-area: col1;
`

export const ImageColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    margin: auto;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
`

export const TopLine = styled.p`
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    font-size: 24px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 1.4;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    font-family: 'Cormorant Garamond', serif;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 400;
    color: #353535;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 15px;
    line-height: 24px;
    color: #403825;

    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`

export const ImgWrapper = styled.div `
    max-width: 300px;
    height: 100%;
    align-items: center;
    
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`
