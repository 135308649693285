import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom';

export const FaqContainer = styled.div `
    padding-top: 20px;
    background: #f9f9f4;
`

export const FaqWrapper = styled.div `
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
`

export const ImageWrapper = styled.div `
    width: 100%;
    height: 300px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        height: 250px;
    }
`

export const Image = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;

    filter: grayscale(40%);
`

export const Title = styled.div `
    text-align: center;
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`

export const QuestionWrapper = styled.div `
    padding: 10px 20px;

    @media screen and (max-width: 480px) {
        padding: 5px 20px;
    }
`

export const Question = styled.h1 `
    font-family: 'Cormorant Garamond', serif;
    font-weight: bold;
    font-size: 25px;
    color: #403825;
    padding: 10px 0;


    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`

export const Answer = styled.p `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300;
    font-size: 20px;
    font-style: italic;
    padding: 0 20px;
    color: #353535;

    @media screen and (max-width: 480px) {
        font-size: 17px;
        padding: 0;
    }
`

export const Link = styled(LinkRouter) `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300;
    font-size: 20px;
    font-style: italic;
    padding: 0 20px;
    color: #353535;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: red;
    }

    @media screen and (max-width: 480px) {
        font-size: 17px;
        padding: 0;
    }
`