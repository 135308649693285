import React, { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { CarouselContainer, CarouselWrapper, ImageSlide, 
    NavWrapper, LeftButton, RightButton, ArrowIcons, Image } from './CarouselElement'

import p2 from './images/IMG_0326.jpeg'
import p6 from '../../images/IMG_0502e.jpg'

const img_arr = [p6, p2]

const ImageCarousel = () => {
    const [xcoord, setX] = useState(0)
    
    const navLeft = () => { (xcoord === 0) ? setX(-100 * (img_arr.length - 1)) : setX(xcoord + 100) }
    const navRight = () => { (xcoord === -100 * (img_arr.length - 1)) ? setX(0) : setX(xcoord - 100) }

    return (
        <>
            <CarouselContainer>
                <CarouselWrapper>
                    {img_arr.map((item, index) => {
                        return(
                            <ImageSlide key={index} xcoord={xcoord}>
                                <Image src={item}></Image>
                            </ImageSlide>
                        )
                    })}

                    <NavWrapper>
                        <LeftButton onClick={navLeft}>
                            <ArrowIcons>
                                <MdKeyboardArrowLeft/>
                            </ArrowIcons>
                        </LeftButton>

                        <RightButton onClick={navRight}>
                            <ArrowIcons>
                                <MdKeyboardArrowRight/>
                            </ArrowIcons>
                        </RightButton>
                    </NavWrapper>
                    
                </CarouselWrapper>
            </CarouselContainer>
        </>
    )
}

export default ImageCarousel
