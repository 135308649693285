import React, { useState } from 'react'

import { IntroContainer, IntroWrapper, IntroContent, 
    Title, Paragraph, Other, Other2, ButtonColumn, ButtonWrapper, ArrowForward, ArrowRight, Button} from './IntroElements'

export const IntroSection2 = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <>
            <IntroContainer>
                <IntroWrapper>
                    <IntroContent>
                        <Title>Andrea's Permanent Makeup</Title>
                        <Paragraph>Andrea's Permanent Makeup is open and available for service 7 days a week. 
                            We are full service Micropigmentation/Permanent makeup and Microblading clinic.</Paragraph>
                        <Other>For your safety and ours, we continue to implement our sanitation rituals, 
                            social distancing, disinfecting all areas and wearing masks and gloves.</Other>
                        <Other>Have any questions or want to set up an appointment? Give us a call at 
                            one of our locations.</Other>
                        <Other2>Marietta: 678-560-7442 or Norcross: 404-386-3793</Other2>
                    </IntroContent>
                    <ButtonColumn>
                        <ButtonWrapper>
                            <Button to='contact' onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                                    Contact Us{hover ? <ArrowForward/> : <ArrowRight/>}
                            </Button>
                        </ButtonWrapper>
                    </ButtonColumn>
                </IntroWrapper>
            </IntroContainer>
        </>
    )
}
