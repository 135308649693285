import styled from 'styled-components'

export const ServicesContainer = styled.div `
    padding-top: 20px;
    background: #f9f9f4;
`

export const ServicesWrapper = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 1200px;
`

export const ImageWrapper = styled.div `
    width: 100%;
    height: 300px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        height: 250px;
    }
`

export const Image = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
`

export const Title = styled.div `
    text-align: center;
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`   

export const InfoWrapper = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 20px;
    //border-bottom: 1px solid #9d998f;
`

export const Details = styled.div `
    width: 100%;
    margin: auto;
    padding: 15px 0;
    border-bottom: 1px solid #9d998f;
`

export const HeaderWrapper = styled.div `
    display: flex;  
    margin-bottom: 10px;   
`

export const Header = styled.h1 `
    font-family: 'Cormorant Garamond', serif;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    color: #403825;
    margin-right: 20px;
`

export const Price = styled.p `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300;
    font-style: italic;
    font-size: 24px;
    color: #403825;
`

export const Description = styled.p `
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    color: #353535;
    font-size: 20px;
    padding: 15px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
    }
`