import React from 'react'
import { ContactContainer, ContactWrapper, TextColumn, Locations, Subheader, Phone, CityHeader, 
    Address, Line, Link } from './ContactInfoElements'

const ContactInfoSection = () => {
    return (
        <>
            <ContactContainer>
                <ContactWrapper>                    
                    <TextColumn>
                        <Subheader>- Visit Us -</Subheader>
                        <Locations>
                            <CityHeader>Marietta</CityHeader>
                            <Address>
                                <Phone href='tel:6785607442'>Phone: 678-560-7442</Phone>
                                <Line>1584 Roswell Rd.</Line>
                                <Line>Marietta, GA 30062</Line>
                                <Link href='https://www.google.com/maps/place/1584+Roswell+Rd,+Marietta,+GA+30062/@33.952468,-84.5079317,17z/data=!3m1!4b1!4m5!3m4!1s0x88f513e61b17907b:0xf938a206166f95ea!8m2!3d33.952468!4d-84.505743'
                                    target='_blank'
                                >Get Directions</Link>
                            </Address>

                            <CityHeader>Norcross</CityHeader>
                            <Address>
                                <Phone href='tel:4043863793'>Phone: 404-386-3793</Phone>
                                <Line>3941 Holcomb Bridge Rd. Ste 100</Line>
                                <Line>PeachTree Corners, GA 30096</Line>
                                <Link href='https://www.google.com/maps/place/3941+Holcomb+Bridge+Rd,+Norcross,+GA+30092/@33.9655779,-84.257046,17z/data=!4m5!3m4!1s0x88f5a08e71c31947:0xa1c8553bca2e59ae!8m2!3d33.9655779!4d-84.2548573'
                                    target='_blank'
                                >Get Directions</Link>
                            </Address>
                        </Locations>
                    </TextColumn>
                    
                    <TextColumn>
                        <Subheader>- Our Hours -</Subheader>
                        <Line>Appointments are available all week Monday through Sunday, 8AM - 8PM</Line>
                    </TextColumn>
                </ContactWrapper>
            </ContactContainer>  
        </>
    )
}

export default ContactInfoSection
