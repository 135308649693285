import React from 'react'
import OwnerSection from '../../components/OwnerSection'

const About = () => {
    return (
        <>
            <OwnerSection/>
        </>
    )
}

export default About
