import React from 'react'
import ImageCarousel from '../../components/ImageCarousel'
import { IntroSection2 } from '../../components/IntroSection2'
import ServicesSection from '../../components/ServicesSection'
import { HomeContainer, HomeWrapper } from './HomeElements'

const Home = () => {
    return (
        <>
            <HomeContainer>
                <HomeWrapper>
                    <ImageCarousel/>
                    <IntroSection2/>
                    <ServicesSection/>

                    {/* <OwnerSection/> */}
                </HomeWrapper>
            </HomeContainer>
        </>
    )
}

export default Home
