import React from 'react'
import { ServicesContainer, ServicesWrapper, Title, Subtitle, ServicesGrid, ServicesLink } from './ServicesElements'
import Card from '../../assets/Card'

import p1 from '../../images/service-images/eyebrow.jpg'
import p2 from '../../images/service-images/eyeliner.jpg'
import p3 from '../../images/service-images/lips.jpg'

const ServicesSection = () => {
    return (
        <>
            <ServicesContainer>
                <ServicesWrapper>
                    <Title>- Our Services -</Title>
                    <Subtitle>
                        Andrea’s Permanent Make-Up provides various services and procedures that are popular amongst 
                        both male and female clients. These procedures consist of eyebrows, eyeliner, lip color, 
                        stretch marks, birthmarks, vitiligo, scar camouflage, freckles, age spots, areola pigment 
                        restoration and medical cosmetic tattoo treatment. These state-of-the-art techniques are 
                        medically proven and specifically designed to be completely safe.
                    </Subtitle>
                    <ServicesGrid>
                        <Card
                            to={{pathname:'/services', scrollto: 'eyeliner'}}
                            title='Eyeliner' 
                            imgsrc={p2}/>
                        <Card
                            to={{pathname:'/services', scrollto: 'lips'}}
                            title='Lips' 
                            imgsrc={p3}/>
                        <Card
                            to={{pathname:'/services', scrollto: 'eyebrows'}}
                            title='Eyebrows' 
                            imgsrc={p1}/>
                    </ServicesGrid>
                    <ServicesLink to='/services'>See all of our services</ServicesLink>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    )
}

export default ServicesSection
