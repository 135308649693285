import styled from 'styled-components'

export const FormContainer = styled.div `
    padding: 20px 20px;
    background: #f9f9f4;
    border-bottom: 1px solid #9d998f;
`

export const FormWrapper = styled.div `
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px;
`

export const FormDesc = styled.div `
    margin: auto;
    color: #353535;
    max-width: 1000px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    padding-bottom: 20px;
`

export const Form = styled.form `
    display: flex;
    flex-direction: column;
`

export const Label = styled.label `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 20px;
    color: #353535;
`

export const Field = styled.div `
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: none;
    width: 100%;

    @media screen and (max-width: 570px) {
        flex-direction: column;
    }
`

export const InputWrapper = styled.div `
    padding-right: 10px;  
    width: 100%;

    @media screen and (max-width: 570px) {
        padding: 10px 0;
    }
`

export const TextInput = styled.input `
    padding: 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #9d998f;
    background: none;
`

export const MessageInput = styled.textarea `
    padding: 10px;
    height: 100px;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid #9d998f;
`

export const SendButton = styled.button `
    padding: 12px 30px;
    max-width: 200px;
    color: white;
    background: #e289a5;
    border: none;
    font-family: 'Cormorant Garamond', serif;
    font-size: 16px;
    letter-spacing: 2.5px;
    cursor: pointer;
    margin: auto;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #f4c0d1;
    }
`

export const ErrorMessage = styled.div `
    background: #a73d26;
    color: white;
    padding: 8px;
    font-size: 12px;
    border-radius: 3px;
    width: max-content;
    margin: 10px 0;
    display: ${({check}) => (check ? 'block' : 'none')}
`