import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa';

export const PopupContainer = styled.div `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PopupWrapper = styled.div `
    position: relative;
`

export const CloseButton = styled(FaTimes) `
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 20px;
`