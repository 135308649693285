import React from 'react'
import {SidebarContainer, SidebarIcon, CloseIcon, 
    SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <>
            <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <SidebarIcon>
                    <CloseIcon/>
                </SidebarIcon>

                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink to='/' onClick={toggle}>HOME</SidebarLink>
                        <SidebarLink to='/about' onClick={toggle}>ABOUT</SidebarLink>
                        <SidebarLink to='/services' onClick={toggle}>SERVICES</SidebarLink>
                        <SidebarLink to='/gallery' onClick={toggle}>GALLERY</SidebarLink>
                        <SidebarLink to='/faq' onClick={toggle}>FAQ</SidebarLink>
                    </SidebarMenu>
                    <SideBtnWrap>
                        <SidebarRoute to='/contact'>CONTACT US</SidebarRoute>
                    </SideBtnWrap>
                </SidebarWrapper>
            </SidebarContainer>
            
        </>
    )
}

export default Sidebar
