import React, {useState} from 'react'
import emailjs from 'emailjs-com'
import { FormContainer, FormWrapper, FormDesc, Form, Label, 
    Field, InputWrapper, TextInput, MessageInput, SendButton, ErrorMessage } from './ContactFormElements'

const ContactForm = () => {
    const [nameCheck, setNameCheck] = useState();
    const [emailCheck, setEmailCheck] = useState();
    const [subCheck, setSubCheck] = useState();
    const [mesCheck, setMesCheck] = useState();

    function checkField(e) {
        e.preventDefault();

        let f_name = e.target[0].value;
        let l_name = e.target[1].value;
        let email = e.target[2].value;
        let subject = e.target[3].value;
        let message = e.target[4].value;
        
        if(nameCheck !== false || emailCheck !== false || subCheck !== false || mesCheck !== false) {
            if(f_name === "" || l_name === "") {
                setNameCheck(true);
            } else {
                setNameCheck(false);
            }
            var re = /\S+@\S+\.\S+/;
            if(!re.test(email)) {
                setEmailCheck(true);
            } else {
                setEmailCheck(false);
            }
            if(subject === "") {
                setSubCheck(true);
            } else {
                setSubCheck(false);
            }
            if(message === "") {
                setMesCheck(true);
            } else {
                setMesCheck(false);
            }
        }
        else if(nameCheck === false && emailCheck === false && subCheck === false && mesCheck === false) {
            sendEmail(e)
        }
    }

    function sendEmail(e) {
        e.preventDefault();
        //console.log("send email");
        emailjs.sendForm('service_e0lrbpn', 'template_we8cdsa', e.target, 'user_pvtVoEc1cycccX8qQFcsE')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()

        setNameCheck()
        setEmailCheck()
        setSubCheck()
        setMesCheck()
    }

    return (
        <>
            <FormContainer>
                <FormWrapper>
                    <FormDesc>
                        If you have any questions that were not addressed in the FAQ page, send 
                        us a message! Please allow us at most 24 hours to respond to your email.
                    </FormDesc>

                    <Form onSubmit={checkField}>
                        <ErrorMessage check={nameCheck}>Please enter first and last name.</ErrorMessage>
                        <Label>Name *</Label>
                        <Field>
                            <InputWrapper>
                                <TextInput placeholder="First Name" type="text" name="first-name"></TextInput>
                            </InputWrapper>
                            <InputWrapper>
                                <TextInput placeholder="Last Name" type="text" name="last-name"></TextInput>
                            </InputWrapper>
                        </Field>

                        <ErrorMessage check={emailCheck}>Email incorrect format.</ErrorMessage>
                        <Label>Email *</Label>
                        <Field>
                            <TextInput placeholder="Email" type="text" name="email"></TextInput>
                        </Field>
                        
                        <ErrorMessage check={subCheck}>Missing subject.</ErrorMessage>
                        <Label>Subject *</Label>
                        <Field>
                            <TextInput placeholder="Subject" type="text" name="subject"></TextInput>
                        </Field>

                        <ErrorMessage check={mesCheck}>Missing message.</ErrorMessage>
                        <Label>Message *</Label>
                        <Field>
                            <MessageInput placeholder="Message" type="text" name="message"></MessageInput>
                        </Field>
                        
                        <Field>
                            <SendButton type="submit" value="Send">SUBMIT</SendButton>
                        </Field>
                    </Form>
                </FormWrapper>
            </FormContainer>
        </>
    )
}

export default ContactForm
