import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

export const Nav = styled.nav `
    background: #e289a5;
    height: 70px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    

    @media screen and (max-with: 960px) {
        transition: 0.8s all ease;
    }
`

export const NavbarContent = styled.div `
    display: flex;
    justify-content: space-between;
    height: 70px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;
`

export const NavLogo = styled(LinkRouter) `
    font-family: 'Pinyon Script', cursive;
    text-decoration:none;
    color: #fff;
    color: black;
    justify-self: flex-start;
    font-size: 1.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1010px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 480px) {
        display: none;
    }
`
export const NavIcon = styled.div `
    display: none;

    @media screen and (max-width: 910px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #353535;
        color: #fff;
    }
`

export const NavMenu = styled.ul `
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 910px) {
        display: none;
    }
`

export const NavItem = styled.li `
    height: 70px;
`

export const NavLink = styled(LinkRouter) `
    font-size: 15px;
    text-decoration: none;
    //color: #403825;
    color: #fff;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 100%;
    cursor: pointer;

    &:hover {
        border-bottom: 3px solid #fff;
        transition: 0.2s ease-in-out;
    }
`

export const NavButton = styled.nav `
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtnLink = styled(LinkRouter) `
    font-size: 15px;
    text-decoration: none;
    padding: 10px 20px;
    background: transparent;
    white-space: nowrap;
    color: black;
    border: 1px solid black;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: bold;

    &:hover {
        background: #fff;
        border-color: #fff;
        color: #8cb7bd;
        color: #e289a5;
    }
`