import styled from 'styled-components'

export const CarouselContainer = styled.div `
    padding: 20px;
    height: 500px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 910px) {
        height: 300px;
        padding: 0;
    }
`

export const CarouselWrapper = styled.div `
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #353535;
    width: 1000px;
`

export const ImageSlide = styled.div `
    position: relative;
    min-width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    transform: ${({xcoord}) => 'translateX(' + xcoord + '%)'};
`

export const Image = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #232a34;
`

export const NavWrapper = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const LeftButton = styled.button `
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 7%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    background-image: linear-gradient(270deg, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.3));

    &:hover {   
        background-image: linear-gradient(270deg, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.4));
    }

    @media screen and (max-width: 910px) {
        width: 15%;
    }
`

export const RightButton = styled.button `
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 7%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    background-image: linear-gradient(90deg, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.3));

    &:hover {   
        background-image: linear-gradient(90deg, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.4));
    }

    @media screen and (max-width: 910px) {
        width: 15%;
    }
`

export const ArrowIcons = styled.div `
    color: #fff;
    font-size: 2rem;
`