import React, { useState } from 'react'
import './App.css';
import { Switch, Route, HashRouter } from 'react-router-dom'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Contact from './pages/Contact'
import FooterSection from './components/FooterSection'
import Faq from './pages/Faq'
import Gallery from './pages/Gallery'

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
      setIsOpen(!isOpen);
      console.log("toggle" + isOpen);
  }

  return (
    <HashRouter basename='/'>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>

      <Switch>
        <Route path='/' component={Home} exact/>
        <Route path='/about' component={About}/>
        <Route path='/services' component={Services}/>
        <Route path='/contact' component={Contact}/>
        <Route path='/FAQ' component={Faq}/>
        <Route path='/gallery' component={Gallery}/>
      </Switch>
      <FooterSection/>
    </HashRouter>
  );
}

export default App;
