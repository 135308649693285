import React from 'react';
import {FooterContainer, FooterWrapper, FooterLinksContainer, 
    FooterLinksWrapper, FooterLinksItems, FooterLinksTitle, 
    FooterLink, FooterLink2, FooterLink3, SocialMediaContainer, SocialMediaWrapper, SocialLogo, WebsiteRights} from './FooterElements';

const FooterSection = () => {
    return (
        <>
            <FooterContainer>
                <FooterWrapper>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinksItems>
                                <FooterLinksTitle>Locations</FooterLinksTitle>
                                <FooterLink2>MARIETTA</FooterLink2>
                                <FooterLink>1584 Roswell Rd.</FooterLink>
                                <FooterLink>Marietta, GA 30062</FooterLink>
                                <FooterLink3>Phone: 678-560-7442</FooterLink3>
                                <FooterLink></FooterLink>
                                <FooterLink2>NORCROSS</FooterLink2>
                                <FooterLink>3941 Holcomb Bridge Rd. Ste 100</FooterLink>
                                <FooterLink>PeachTree Corners, GA 30096</FooterLink>
                                <FooterLink3>Phone: 404-386-3793</FooterLink3>
                            </FooterLinksItems>
                        </FooterLinksWrapper>

                        <FooterLinksWrapper>
                            <FooterLinksItems>
                                <FooterLinksTitle>Our Hours</FooterLinksTitle>
                                <FooterLink>Mon 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Tue 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Wed 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Thu 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Fri 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Sat 8:00AM - 8:00PM</FooterLink>
                                <FooterLink>Sun 8:00AM - 8:00PM</FooterLink>
                                <FooterLink2>BY APPOINTMENTS ONLY</FooterLink2>
                            </FooterLinksItems>
                        </FooterLinksWrapper>

                    </FooterLinksContainer>

                    <SocialMediaContainer>
                        <SocialMediaWrapper>
                            <SocialLogo to='/'>Andrea's Permanent Make-Up</SocialLogo>
                            <WebsiteRights>Andrea's Permanent Make-Up © {new Date().getFullYear()} All rights reserved</WebsiteRights>
                        </SocialMediaWrapper>
                    </SocialMediaContainer>
                </FooterWrapper>
            </FooterContainer>
        </>
    )
}

export default FooterSection