import React from 'react'
import ProfileImage from '../../images/gloria.jpg'
import { OwnerContainer, OwnerWrapper, OwnerContent, 
    TextColumn, TextWrapper, TopLine, Heading, Subtitle, 
    ImageColumn, ImgWrapper, Img } from './OwnerElements'


const OwnerSection = () => {
    return (
        <>
            <OwnerContainer>
                <OwnerWrapper>
                    <OwnerContent>
                        <TextColumn>
                            <TextWrapper>
                                <TopLine>Meet the owner</TopLine>
                                <Heading>Gloria Gutierrez</Heading>
                                <Subtitle>
                                Gloria is a licensed medical aesthetician with over 25 years’ experience in the cosmetic 
                                tattooing industry. She trained at the acclaimed Tinny Beauty International School of 
                                Permanent Cosmetics in New York, where she received a comprehensive education in the art 
                                of permanent cosmetic makeup. She is also a certified member of The Society of Permanent 
                                Cosmetic Professionals (SPCP) and performed thousands of procedures for eyebrows, 
                                eyeliner, lip color, freckles, beauty mark, scar camouflage, skin recolorization and 3D 
                                areola reconstruction. Want to know more? Give us a call or schedule an appointment at 
                                one of our two locations in Marietta or Norcross.
                                </Subtitle>
                            </TextWrapper>
                        </TextColumn>

                        <ImageColumn>
                            <ImgWrapper>
                                <Img src={ProfileImage} alt='Headshot'/>
                            </ImgWrapper>
                        </ImageColumn>

                    </OwnerContent>
                </OwnerWrapper>
            </OwnerContainer>
        </>
    )
}

export default OwnerSection
