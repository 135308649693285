import React from 'react'
import { FaqContainer, FaqWrapper, ImageWrapper, Image, Title, 
    QuestionWrapper, Question, Answer, Link } from './FaqElements'
import img from '../../images/IMG_0326.jpeg'

const Faq = () => {
    return (
        <>
            <FaqContainer>
                <FaqWrapper>
                    <ImageWrapper>
                        <Image src={img}></Image>
                    </ImageWrapper>

                    <Title>- Frequently Asked Questions -</Title>

                    <QuestionWrapper>
                        <Question>
                            Why consider cosmetic tattooing? 
                        </Question>
                        <Answer>
                            If you are a busy mom or a professional who doesn’t have time to apply makeup every day, 
                            permanent makeup is an excellent solution. Perhaps you have a post- surgical scar that 
                            bothers you, or have had a mastectomy? We can help restore your natural appearance with 
                            cosmetic tattooing. Whether it is the desire to wake up with perfectly sculpted, yet 
                            natural looking eyebrows, a flush of color on the lips, or to make your eyes pop and lashes 
                            look fuller, Andrea's Permanent Makeup will get you there. 
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Are there many colors to choose from?
                        </Question>
                        <Answer>
                            Yes, there are many shades of blonde, browns and auburn for eyebrows. We will try on many 
                            shades prior to the procedure to find a color that looks natural on your skin tone. 
                            Eyeliner can be done in black, a range of browns or in blue tones. There are also many 
                            shades for lip color and skin camouflage. 
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Do I get to decide on the shape and color?
                        </Question>
                        <Answer>
                            Yes, we will try on many shades of color as well as draw on the shape prior to implanting the 
                            pigment. While you have the final say on shape, color and thickness, we will also help 
                            guide you on what looks best with your natural features.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Is it painful?
                        </Question>
                        <Answer>
                            2 types of numbing cream are used, making the procedure very comfortable for most people. 
                            Slight discomfort at times is possible, but for the most part it is very comfortable, and can 
                            actually even be relaxing for some. Lips and areola can be a bit more tender as there are many 
                            blood vessels and nerve endings.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Do you do Microblading or tattooing? Or what kind of technique do you use? 
                        </Question>
                        <Answer>
                            Yes, Gloria does do microblading, however, she prefers to use a technique called Softap or use 
                            a PMU (Permanent Makeup) machine. Softap is similar to microblading in that it creates natural 
                            looking hair like lines and uses a hand held pen rather than a tattoo machine. However, it is 
                            NOT cutting or slicing the skin and setting color on top to soak into the grooves (which is 
                            what microblading does). She is manually tapping the pigment into the skin with little needles, 
                            like a hand poke tattoo. This allows for the color to be deposited into the skin without the 
                            trauma to the tissue that the cutting causes. Cutting grooves into the tissue (microblading) 
                            allows the pigment to migrate or seep into the surrounding tissue, which causes the strokes to 
                            look blurred over time. Softap and/or PMU lasts longer, and the lines stay more crisp over 
                            time, and a bonus it’s a lot less painful! A PMU machine is a type of tattoo pen, however, we 
                            do not use tattoo ink. Tattoo ink (which is truly permanent) is a larger molecule and looks 
                            heavy and blown out over time, like an old blurred tattoo. Tattoo ink often changes to a 
                            greenish or blue/grey tone over the years, and becomes much harder to cover in the future 
                            because that heavy carbon ink is there permanently. 

                            PMU uses permanent makeup pigment, which is made of iron oxide, or a blend of iron oxide and a 
                            little carbon for longevity. Iron oxide is a smaller molecule, so it looks more natural and you 
                            can get finer crisper lines, but it does break down over time (fades to a lighter version of 
                            the original color). Most people have their permanent makeup refreshed every 1.5 to 3 years.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            How long does the appointment take?
                        </Question>
                        <Answer>
                            Procedures range from 45 minutes to 2+ hours, depending on the area. The average appointment for 
                            eyebrows or eyeliner is 1 and ½ hours. This includes filling out paperwork, allowing the numbing to 
                            take effect, discussing the color etc, and doing the procedure. 
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            What is the recovery like?
                        </Question>
                        <Answer>
                            You can expect slight 
                            redness around the eyebrows for a few hours after the procedure, and slight swelling of the eyelids 
                            and lips for 24 hours. You will need to avoid swimming and steam rooms for the first 4 days. 
                            During the first week, the color can look a shade or two darker than what it will heal to be. Some 
                            people experience some flaking or peeling of the surface pigment during the healing process. A 
                            touch up is then done 1-2 months later to smooth out any color that may have flaked during the 
                            initial healing process. Photos in the Gallery were taken immediately after the procedure.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            How long does it last?
                        </Question>
                        <Answer>
                            Permanent makeup is sometimes a bit of a misnomer as it does fade over time, depending on your 
                            skin type, metabolism and how well you care for them. Most people prefer to touch up the color 
                            every 2-5 years.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Will the color change over time?
                        </Question>
                        <Answer>
                            We use only organic pigments made in the US that tends to stay true 
                            to its color over time. Blacks do not turn blue, and browns should not turn pink. The color will 
                            just slowly soften over time. It is advised to avoid using skin lightening products on your 
                            permanent makeup, such as hydroquinone or retinols as this will break down the color over time.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            What are the risks?
                        </Question>
                        <Answer>
                            Anytime you break the skin there is a risk of infection. We use one-time use, disposable sterile 
                            needles and supplies, making infection very unlikely. It is recommended to not touch the area with 
                            dirty hands while it is healing, and use clean makeup brushes to avoid infection. The pigment is FDA 
                            approved, and hypoallergenic making the risk of an allergic reaction quite low. It contains no 
                            vegetable dyes or chemicals, and consists only of iron oxide and glycerin.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            What should I do to prepare for the appointment?
                        </Question>
                        <Answer>
                            You will want to avoid blood thinning agents such as ibuprofen, aspirin, alcohol and fish oil for a 
                            few days prior to the appointment if possible (Do not stop any prescribed medication prior to 
                            discussing it with your medical provider). Do not wear any mascara or eye-makeup in to the 
                            appointment if you are having eyeliner done. If you are having eyebrows done, please wear your eyebrow 
                            makeup in so that we can see what color and shape you typically wear.
                        </Answer>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <Question>
                            Do you accept Credit Cards?
                        </Question>
                        <Answer>
                            Yes, credit cards are accepted as well as Cash, Check, Venmo or Paypal.
                        </Answer>
                    </QuestionWrapper>

                    <QuestionWrapper>
                        <Question>
                            Still have questions?
                        </Question>
                        <Link to='/contact'>
                            Gloria offers free consultation to address any concerns. Click here to visit our contact page to give us a call or send a message. 
                        </Link>
                    </QuestionWrapper>
                </FaqWrapper>
            </FaqContainer>
        </>
    )
}

export default Faq
