import styled from 'styled-components'

export const ContactContainer = styled.div `
    background: #f9f9f4;
    max-width: 1200px;
    margin: auto;
    padding: 20px 0;
    border-bottom: 1px solid #9d998f;
`

export const ContactWrapper = styled.div `
`

export const TextColumn = styled.div `
    color: #403825;
    display: inline-block;
    width: 100%;
    padding: 0 20px;
`

export const Subheader = styled.h1 `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 30px;

    @media screen and (max-width: 420px) {
        font-size: 24px;
        text-align: center;
    }
`

export const Phone = styled.a `
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    color: #353535;
    font-size: 20px;
    padding: 0 15px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
        padding: 0;
    }

    &:hover {
        color: red;
    }
`

export const Locations = styled.div `
    padding: 10px 20px;

    @media screen and (max-width: 420px) {
        padding: 0;
    }
`

export const CityHeader = styled.h1 `
    font-family: 'Cormorant Garamond', serif;
    //text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    color: #353535;

    @media screen and (max-width: 420px) {
        font-size: 20px;
    }
`

export const Address = styled.div `
    padding: 10px 10px;
    display: inline-block;
`

export const Line = styled.p `
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    color: #353535;
    font-size: 20px;
    padding: 0 15px;

    @media screen and (max-width: 480px) {
        font-size: 17px;
        padding: 0;
    }
`

export const Link = styled.a `
    font-family: 'Cormorant Garamond', serif;
    color: #353535;
    font-size: 20px;
    padding: 15px;
    text-decoration: none;
    transition: 0.3s ease-in-out;

    &:hover {
        color: red;
    }

    @media screen and (max-width: 480px) {
        font-size: 17px;
        padding: 0;
    }
`


