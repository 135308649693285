import styled from 'styled-components'

export const GalleryContainer = styled.div `
    background: #f9f9f4;
`

export const GalleryWrapper = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 1200px;
`