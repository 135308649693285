import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

export const CardRouter = styled(LinkRouter) `
    display: inline-block;
    cursor: pointer;
    width: 275px;
    text-decoration: none;
    color: #403825;
`

export const CardContainer = styled.div `
    border: 1px solid #9d998f;
    background: #f9f9f4;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`

export const ImageWrapper = styled.div `
    padding: 10px;
    height: 200px;
`

export const Image = styled.img `
    overflow: hidden;
    width: 100%;
    object-fit: cover;
    filter: grayscale(50%);
`

export const TextWrapper = styled.div `
    padding: 0 20px 20px;
`

export const Title = styled.h3 `
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    text-transform: uppercase;
`

