import React from 'react'
import { GalleryContainer, GalleryWrapper } from './GalleryElements'
import GallerySection from '../../components/GallerySection'

const Gallery = () => {
    return (
        <>
            <GalleryContainer>
                <GalleryWrapper>
                    <GallerySection/>
                </GalleryWrapper>
            </GalleryContainer>
        </>
    )
}

export default Gallery
