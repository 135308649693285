import React from 'react'
import { ServicesContainer, ServicesWrapper, ImageWrapper, Image, 
    Title, InfoWrapper, Details, HeaderWrapper, Header, Price, Description } from './ServicesElements'
import img from '../../images/IMG_0326.jpeg'

const Services = (props) => {
    const scrollTo = () => {
        if(props.location.scrollto !== undefined) {
            const component = document.getElementById(props.location.scrollto); 
            
            window.scrollTo({top: component.offsetTop - 70, behavior: 'smooth'});

            props.location.scrollto = undefined;
        }
    }

    return (
        <>
            <ServicesContainer onLoad={scrollTo}>
                <ServicesWrapper>
                    <ImageWrapper>
                        <Image src={img}></Image>
                    </ImageWrapper>
                    <Title>- Our Services -</Title>

                    <InfoWrapper>
                        <Details id='eyeliner'>
                            <HeaderWrapper>
                                <Header>Permanent Eyeliner</Header>
                            </HeaderWrapper>
                            <Description>
                                A subtle, natural look, mimicking thousands of tiny eyelashes with the implantation of 
                                pigments in the lash line. Additional shadowing of color can be added for either a more 
                                soft, natural line or a bold, defined one. Ophthalmologists recommend Intradermal Cosmetic 
                                procedures for those who are allergic to conventional makeup and/or for those wearing contact 
                                lenses.
                            </Description>
                            <Price>Upper eyeliner: $250.00</Price>
                            <Price>Lower eyeliner: $250.00</Price>
                            <Price>Complete eyeliner: $500.00</Price>
                        </Details>

                        <Details id='lips'>
                            <HeaderWrapper>
                                <Header>Permanent Lips Liner/Color</Header>
                            </HeaderWrapper>
                            <Description>
                                Beautiful, sensual lips are created by adding natural color where needed, achieving instant 
                                fullness and definition. Subtle corrections can be made to thin, uneven, or asymmetrical lips; 
                                and the size and shape can be altered as desired. Facial lines around the mouth are instantly 
                                camouflaged and lipstick bleed is eliminated.
                            </Description>
                            <Price>Lip liner: $299.00</Price>
                            <Price>Full lips: $500.00</Price>
                        </Details>

                        <Details id='eyebrows'>
                            <HeaderWrapper>
                                <Header>Eyebrows</Header>
                            </HeaderWrapper>
                            <Description>
                                Framing and balancing your face with well designed and groomed eyebrows will give a 
                                lift to your eyes and a more youthful appearance. Eyebrows permanent makeup is a cosmetic 
                                technique which employs permanent pigmentation of the dermis. Micro-blading addresses 
                                various concerns such as sparse, thin, or naturally light eyebrows, by adding fullness and 
                                definition without compromising a natural look. These procedures are wonderful enhancement for both 
                                men and women or those who are experiencing sparse eyebrows due to age, disease such as alopecia, 
                                chemotherapy, or a genetic disturbance.
                            </Description>
                            <Price>Full eyebrows: $299.00</Price>
                            <Price>Microblading: $299.00</Price>
                            <Price>Cosmetic tattoo: $299.00</Price>
                        </Details>
                        
                        <Details id='eyelash'>
                            <HeaderWrapper>
                                <Header>Eyelash Extentions</Header>
                                <Price>$125.00</Price>
                            </HeaderWrapper>
                            <Description>
                                Eyelash extensions are used to enchance the length, fullness, curls, and thinkness of your 
                                eyelashes. This method uses cyanoacrylate adhesive to apply the extensions 1-4 mm from the base 
                                of the natural eyelash. The materials used includes: mink, silk, synthetic, or human.
                            </Description>
                        </Details>

                        <Details id='other'>
                            <HeaderWrapper>
                                <Header>Other procedures</Header>
                            </HeaderWrapper>
                            <Price>- Stretch marks</Price>
                            <Price>- Birthmarks</Price>
                            <Price>- Vitiligo</Price>
                            <Price>- Scar camouflage</Price>
                            <Price>- Freckles</Price>
                            <Price>- Age spots</Price>
                            <Price>- Areola pigment restoration</Price>
                            <Description>
                                Prices for the services below varies. For more information on these procedures, please give us a 
                                call for free consultation.
                            </Description>
                        </Details>

                    </InfoWrapper>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    )
}

export default Services
