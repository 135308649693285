import styled from 'styled-components'
import {Link as LinkRouter} from 'react-router-dom'

export const FooterContainer = styled.footer`
    /* background: #010522; */
    background: #f9f9f4;
`

export const FooterWrapper = styled.div `
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div `
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinksItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 250px;
    box-sizing: border-box;
    color: #403825;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinksTitle = styled.h1`
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 16px;

    @media screen and (max-width: 420px) {
        font-size: 14px;
    }
`;

export const FooterLink = styled.small`
    font-family: 'Cormorant Garamond', serif;
    font-size: 18px;
    font-weight: 300;
    color: #403825;
    text-decoration: none;
    margin-bottom: 0.5rem;


    @media screen and (max-width: 420px) {
        font-size: 14px;
    }
`;

export const FooterLink2 = styled.small`
    font-family: 'Cormorant Garamond', serif;
    font-size: 18px;
    font-weight: bold;
    color: #403825;
    text-decoration: none;
    margin-bottom: 0.5rem;


    @media screen and (max-width: 420px) {
        font-size: 14px;
    }
`;

export const FooterLink3 = styled.small`
    font-size: 14px;
    font-weight: 300;
    color: #403825;
    text-decoration: none;
    margin-bottom: 0.5rem;


    @media screen and (max-width: 420px) {
        font-size: 14px;
    }
`;


export const SocialMediaContainer = styled.section`
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;   
`

export const SocialLogo = styled(LinkRouter)`
    //font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    color: #403825;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

export const WebsiteRights = styled.small`
    color: #403825;
    margin-bottom: 16px;
    align-items: center;

    /* @media screen and (max-width: 420px) {
        font-size: 12px;
    } */
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`

export const SocialIconLink = styled.a`
    color: #403825;
    font-size: 24px;
`