import React, { useState } from 'react'
import Popup from '../../assets/Popup'
import { GalleryContainer, GalleryWrapper, Title, Gallery, Image,
    Label, ImageSelector, ThumbnailWrapper, Thumbnail } from './GalleryElements'

const GallerySection = () => {
    const [selectedImg, changeImg] = useState();
    const [buttonPopup, setButtonPopup] = useState(false);
    const changeImage = (e) => { 
        changeImg(e.target.src) 
        setButtonPopup(true);
    }

    const importAll = (r) => {
        return r.keys().map(r);
    }

    const eyeliner_img = importAll(require.context('./images/eyeliner', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    const lips_img = importAll(require.context('./images/lips', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    const eyebrow_img = importAll(require.context('./images/eyebrow', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    const hairline_img = importAll(require.context('./images/hairline', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    const scar_img = importAll(require.context('./images/scars', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    const others_img = importAll(require.context('./images/eyelash', false, /\.(PNG|JPE?G|png|jpe?g)$/));
    


    return (
        <>
            <GalleryContainer>
                <GalleryWrapper>
                    <Title>- Gallery -</Title>
                    <Gallery>
                        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                            <Image src={selectedImg}></Image>
                        </Popup>

                        <Label>Eyeliner</Label>
                        <ImageSelector>
                            {eyeliner_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>

                        <Label>Lips</Label>
                        <ImageSelector>
                            {lips_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>
                        
                        <Label>Eyebrows</Label>
                        <ImageSelector>
                            {eyebrow_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>

                        <Label>Eyelashes</Label>
                        <ImageSelector>
                            {others_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>

                        <Label>Hairline</Label>
                        <ImageSelector>
                            {hairline_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>

                        <Label>Scar Camouflage</Label>
                        <ImageSelector>
                            {scar_img.map((item, index) => {
                                return(
                                    <ThumbnailWrapper key={index} onClick={changeImage}>
                                        <Thumbnail src={item.default}></Thumbnail>
                                    </ThumbnailWrapper>
                                )
                            })}
                        </ImageSelector>
                    </Gallery>
                </GalleryWrapper>
            </GalleryContainer>
        </>
    )
}

export default GallerySection
