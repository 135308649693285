import styled from 'styled-components'

export const GalleryContainer = styled.div `
    background: #f9f9f4;
    max-width: 1200px;
    margin: auto;
    padding: 20px 0;
    border-bottom: 1px solid #9d998f;
`

export const GalleryWrapper = styled.div `
    padding: 0 10px;
`

export const Title = styled.div `
    text-align: center;
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`

export const Gallery = styled.div `
    display: flex;
    flex-direction: column;
`

export const Image = styled.img `
    width: 100%;
    max-width: 800px;
    max-height: 600px;
`

export const SelectorWrapper = styled.div `
    display: flex;
`

export const Label = styled.div `
    color: #403825;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 2.4px;
    margin-top: 15px;
    padding-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`

export const ImageSelector = styled.div `
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(8, 0fr);
    gap: 10px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, 0fr);
    }

    @media screen and (max-width: 300px) {
        grid-template-columns: repeat(3, 0fr);
    }
`

export const ThumbnailWrapper = styled.div `
    cursor: pointer;
`

export const Thumbnail = styled.img `
    height: 100px;
    width: 100px;
    overflow: hidden;
    object-fit: cover;

    @media screen and (max-width: 420px) {
        height: 70px;
        width: 70px;
    }
`