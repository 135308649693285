import React from 'react'
import { FaBars } from 'react-icons/fa'
import { Nav, NavbarContent, NavLogo, 
    NavIcon, NavMenu, NavItem, NavLink, NavButton, NavBtnLink } from './NavbarElements'

const Navbar = ({toggle}) => {
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <>
            <Nav>
                <NavbarContent>
                    <NavLogo to='/' onClick={scrollToTop}>Andrea's Permanent Make-Up</NavLogo>
                    
                    <NavIcon onClick={toggle}>
                            <FaBars/>
                    </NavIcon>

                    <NavMenu>
                        <NavItem>
                            <NavLink to='/'>HOME</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/about'>ABOUT</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/services'>SERVICES</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/gallery'>GALLERY</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/FAQ'>FAQ</NavLink>
                        </NavItem>

                        <NavButton>
                            <NavBtnLink to="/contact">CONTACT US</NavBtnLink>
                        </NavButton>
                    </NavMenu>
                    
                </NavbarContent>
            </Nav>
        </>
    )
}

export default Navbar
